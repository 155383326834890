import { t } from '@common/hooks/use-t.hook';

export const CONST = {
    DEFAULT_COUNTRY_ABBR: 'GB',
    REFETCH_INTERVAL_IN_MS: 5 * 60 * 1000,
    BLOCK_UPCOMING_DELIVERY_BEFORE_CUTOFF_IN_MINUTES: 30,
    CURRENCY_SYMBOL: process.env.REACT_APP_CURRENCY_SYMBOL || '£',
    CURRENCY_CODE: process.env.REACT_APP_CURRENCY_CODE,
    LIST_LIMIT: +(process.env.REACT_APP_LIST_LIMIT || 20),
    REACT_QUERY_STALE_TIME: Number.MAX_VALUE,
    SERVER_DATE_FORMAT: 'YYYY-MM-DD',
    MAX_DATE: new Date(8640000000000000),
    DEBOUNCE: 400,
    CANCELLATION_REDIRECT_URL: process.env.REACT_APP_CANCELLATION_REDIRECT_URL,

    cookieCoupon: 'ob_coupon',
    cookieLandingUrl: 'ob_landing_url',
    cookieSku: 'ob_sku',
    reactivate: 'reactivate',
    queryCoupon: 'coupon',
    queryToken: 'token',
    queryEmail: 'email',
    queryCode: 'recovery_code',
    queryID: 'id',
    querySubscriptionID: 'subscription-id',
    queryPostcode: 'postcode',
    querySKU: 'sku',
    queryCustomerID: 'customer-id',
    queryPrevValue: 'prev-value',
    queryRedeemCoupon: 'redeem-coupon',
    queryUsername: 'username',
    timeFormat: 'HH:mm',
    thisFieldRequired: t('This field is required'),
    invalidEmail: t('Invalid email'),
    invalidPassword: t('Your password requires min 8 symbols, uppercase letters, lowercase letters, numbers'),
    invalidCard: t('Invalid card information'),

    localStorageKey_LayoutSettingsKey: 'layout-settings',
    sessionStorageKey_SSO: 'SSO',
    sessionStorageKey_PendingCoupon: 'pending-coupon',

    sessionStorageKey_registration: 'registration',
    sessionStorageKey_registrationStorage: 'registration-storage',
    sessionStorageKey_subscriptionId: 'subscriptionId',
    sessionStorageKey_redirectUrl: 'redirectUrl',

    boxCustomisation_produce_limit: 3,
};
