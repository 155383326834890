import { CONST } from '@common/constants/const.constant';
import { IRouteConfig } from '@common/interfaces/route.interface';
import { ILinkItem } from '@common/utils/make-links.util';
import React from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router';

interface IProps {
    loginUrl: ILinkItem;
    dontHavePermissionUrl: ILinkItem;
    config: IRouteConfig;
    getToken: () => string | undefined;
}

export const RenderRoutes = ({ config, getToken, loginUrl, dontHavePermissionUrl }: IProps): JSX.Element => {
    const history = useHistory();
    return (
        <Switch>
            {config.routes.map((route) => {
                const Component = route.component;
                return (
                    <Route
                        key={route.path.toString()}
                        exact={route.exact}
                        path={route.path.toString()}
                        render={(props): JSX.Element => {
                            if (route.isPrivate && !getToken()) {
                                sessionStorage.setItem(CONST.sessionStorageKey_redirectUrl, history.location.pathname);
                                return <Redirect to={loginUrl.toString()} from={history.location.pathname} />;
                            }
                            if (!route.hasAccess()) {
                                return <Redirect to={dontHavePermissionUrl.toString()} from={history.location.pathname} />;
                            }
                            return <Component {...props} />;
                        }}
                    />
                );
            })}

            {config.redirects.map((r) => {
                return (
                    <Redirect
                        key={r.to.toString()}
                        to={{ pathname: r.to.toString(), state: r.state }}
                        from={r.from?.toString()}
                    />
                );
            })}
        </Switch>
    );
};
