import { UID } from '@common/interfaces/uid.interface';
import { EMPTY_LINK_ITEM, ILinkItem, LinkItem, makeLinks } from '@common/utils/make-links.util';

interface IAppLink {
    invite: ILinkItem;
    external_oddbox: ILinkItem;
    external_waiting_list: ILinkItem;
    external_chargebee_js: ILinkItem;
    external_terms1: ILinkItem;
    external_terms2: ILinkItem;
    external_terms_refer_a_friend: ILinkItem;
    external_boxes: ILinkItem;
    external_new_request: ILinkItem;
    external_faq: ILinkItem;
    external_recipes: ILinkItem;
    external_box_contents: ILinkItem;
    external_food_allergies: ILinkItem;

    external_mailto: ILinkItem;
    external_how_it_works: ILinkItem;
    external_how_it_works_1: ILinkItem;
    external_link_impact: ILinkItem;
    external_growers: ILinkItem;
    external_the_why: ILinkItem;
    external_delivery: ILinkItem;
    external_blog: ILinkItem;
    external_contact_us: ILinkItem;
    external_cancel_contact_us: ILinkItem;

    index: ILinkItem;
    auth: {
        login: ILinkItem;
        // forgot password flow (3 screens)
        forgotPassword: ILinkItem;
        checkEmail: ILinkItem;
        //got email on this url
        recoveryCode: ILinkItem;
        // recovery password when admin changed it (set temporary password)
        passwordChangeRequired: ILinkItem;
        //migrated customers, got email on this url
        changePassword: ILinkItem;
        checkSso: ILinkItem;
        signOut: ILinkItem;
    };

    home: ILinkItem;

    register: {
        checkDelivery: ILinkItem;
        firstBox: ILinkItem;
        accountDetails: ILinkItem;
        delivery: ILinkItem;
        preferences: ILinkItem;
        payment: ILinkItem;
        summary: ILinkItem;
        invite: ILinkItem;
        testStripe: ILinkItem;
        deliveryDetails: ILinkItem;
    };

    account: {
        accountDetails: {
            info: ILinkItem;
            password: ILinkItem;
            cancel: ILinkItem;
            payment: ILinkItem;
            preferences: ILinkItem;
        };
        manageBox: {
            id: (id?: UID) => ILinkItem;
            add: {
                // id: (id?: UID) => ILinkItem;
            };
            swapBox: {
                id: (id?: UID) => ILinkItem;
            };
            reactivateBox: {
                id: (id?: UID) => ILinkItem;
            };
        };

        orderHistory: {
            id: (id?: UID) => ILinkItem;

        };
        referAFriend: ILinkItem;
        rewards: ILinkItem;
    };
    other: {
        swagger: ILinkItem;
        notAuthorized: ILinkItem;
        dontHavePermission: ILinkItem;
        notFound: ILinkItem;
        unsubscribeAll: ILinkItem;
    };
    subscribe: ILinkItem;
    unsubscribe: ILinkItem;
}

export const LINKS = makeLinks<IAppLink>({
    invite: EMPTY_LINK_ITEM,
    external_oddbox: new LinkItem('https://www.oddbox.co.uk'),
    external_waiting_list: new LinkItem('https://www.oddbox.co.uk/postcode-waitlist'),
    external_chargebee_js: new LinkItem('https://js.chargebee.com/v2/chargebee.js'),
    external_boxes: new LinkItem('https://www.oddbox.co.uk/home-box'),
    external_terms1: new LinkItem('https://www.oddbox.co.uk/terms-and-conditions'),
    external_terms2: new LinkItem('https://www.oddbox.co.uk/terms-conditions'),
    external_terms_refer_a_friend: new LinkItem('https://www.oddbox.co.uk/terms-conditions-refer-a-friend'),
    external_mailto: new LinkItem('mailto&hello@oddbox.co.uk'),
    external_new_request: new LinkItem('https://support.oddbox.co.uk/hc/en-gb/requests/new'),
    external_faq: new LinkItem('https://support.oddbox.co.uk/hc/en-gb/'),
    external_recipes: new LinkItem('https://www.oddbox.co.uk/recipes'),
    external_box_contents: new LinkItem('https://www.oddbox.co.uk/box-contents'),
    external_food_allergies: new LinkItem(
        'https://support.oddbox.co.uk/hc/en-gb/articles/8964266507026-I-have-allergies-dietary-restrictions-is-Oddbox-suitable-for-me-',
    ),
    external_how_it_works: new LinkItem('https://www.oddbox.co.uk/how-it-works'),
    external_how_it_works_1: new LinkItem('https://www.oddbox.co.uk/how-it-works-1'),
    external_link_impact: new LinkItem('https://www.oddbox.co.uk/impact-methodology'),
    external_growers: new LinkItem('https://www.oddbox.co.uk/ourgrowers'),
    external_the_why: new LinkItem('https://www.oddbox.co.uk/why'),
    external_delivery: new LinkItem('https://www.oddbox.co.uk/delivery'),
    external_blog: new LinkItem('https://www.oddbox.co.uk/blog'),
    external_contact_us: new LinkItem('https://support.oddbox.co.uk/hc/en-gb/requests/new'),
    external_cancel_contact_us: new LinkItem('https://support.oddbox.co.uk/hc/en-gb/requests/new?ticket_form_id=17951092615314'),

    subscribe: EMPTY_LINK_ITEM,
    unsubscribe: EMPTY_LINK_ITEM,

    index: EMPTY_LINK_ITEM,

    other: {
        swagger: EMPTY_LINK_ITEM,
        notAuthorized: EMPTY_LINK_ITEM,
        dontHavePermission: EMPTY_LINK_ITEM,
        notFound: EMPTY_LINK_ITEM,
        unsubscribeAll: EMPTY_LINK_ITEM,
    },
    auth: {
        login: EMPTY_LINK_ITEM,
        checkEmail: EMPTY_LINK_ITEM,
        forgotPassword: EMPTY_LINK_ITEM,
        changePassword: EMPTY_LINK_ITEM,
        recoveryCode: EMPTY_LINK_ITEM,
        passwordChangeRequired: EMPTY_LINK_ITEM,
        checkSso: EMPTY_LINK_ITEM,
        signOut: EMPTY_LINK_ITEM,
    },
    home: EMPTY_LINK_ITEM,

    register: {
        checkDelivery: EMPTY_LINK_ITEM,
        firstBox: EMPTY_LINK_ITEM,
        accountDetails: EMPTY_LINK_ITEM,
        delivery: EMPTY_LINK_ITEM,
        preferences: EMPTY_LINK_ITEM,
        payment: EMPTY_LINK_ITEM,
        summary: EMPTY_LINK_ITEM,
        invite: EMPTY_LINK_ITEM,
        testStripe: EMPTY_LINK_ITEM,
        deliveryDetails: EMPTY_LINK_ITEM,
    },
    account: {
        accountDetails: {
            info: EMPTY_LINK_ITEM,
            password: EMPTY_LINK_ITEM,
            cancel: EMPTY_LINK_ITEM,
            payment: EMPTY_LINK_ITEM,
            preferences: EMPTY_LINK_ITEM,
        },
        manageBox: {
            id: () => EMPTY_LINK_ITEM,
            add: {
                // id: () => EMPTY_LINK_ITEM,
            },
            swapBox: {
                id: () => EMPTY_LINK_ITEM,
            },
            reactivateBox: {
                id: () => EMPTY_LINK_ITEM,
            },
        },
        orderHistory: {
            id: () => EMPTY_LINK_ITEM,
        },
        referAFriend: EMPTY_LINK_ITEM,
        rewards: EMPTY_LINK_ITEM,
    },
});
